import BackbarLogo from 'assets/img/logo_backbar.png';
import Ticket from 'assets/img/barlleno-ticket.png';

function App() {
  return (
    <div className="w-full h-screen flex flex-col lg:gap-20">
      { /* Header*/}
      <header className="w-full bg-primary flex justify-center px-4 py-6 text-white">
        <img src={BackbarLogo} alt='Backbar' className="w-[200px] md:w-[250px] lg:w-[300px]" />
      </header>

      <main className="w-full relative flex flex-col items-center lg:flex-row lg:justify-end lg:h-full">
        <img src={Ticket} alt='Ticket' className="absolute h-[200px] min-[375px]:h-[220px] lg:h-[320px] xl:h-[400px] top-2 lg:top-auto lg:left-72 xl:left-96" />

        <section className="card w-[90%] lg:h-full sm:w-4/5 md:w-3/5 mt-36 min-[375px]:mt-40 mb-16 px-6 lg:pl-40 lg:pr-20 pt-16 pb-12 lg:my-0 lg:py-0 flex flex-col items-center lg:justify-center gap-8 text-center rounded-lg">
          <h1 className="text-primary text-3xl lg:text-[40px] font-bold">Has decidido eliminar tu cuenta</h1>

          <article>
            <p className="font-semibold lg:text-xl">Recuerda que una vez que elimines tu cuenta no podrás recuperar tu información. Ingresa tu correo electrónico y recibirás un código para poder eliminar tu cuenta:</p>
          </article>

          <input
            type="text"
            name="email"
            id="email"
            placeholder="Escribe aquí tu correo"
            className="w-full xl:w-3/5 p-4 border border-tertiary rounded-md text-lg xl:text-xl text-secondary font-semibold placeholder:text-secondary text-ellipsis overflow-hidden ..."
          />

          <button className="w-full p-4 bg-primary text-white text-xl font-bold rounded-lg xl:w-3/5">Enviar</button>
        </section>
      </main>
    </div>

  );
}

export default App;
